.MuiButton-root {
    &.MuiButton-contained, &.MuiButton-outlined {
        border-radius: 40px;
        box-shadow: none;
        &:active {
            box-shadow: none;
        }
    }
    a {
        text-decoration: none;
        color: inherit;
        margin: -6px;
        padding: 6px;
        width: 100%;
    }
    &.white {
        background: #ffff;
        color: #07231F;
        &:hover {
            color: white;
        }
    }
    &.selected {
        background: #52C7DB;
        color: white;
    }
}
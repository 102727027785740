.ResetPassword {
  .MuiFormGroup-root {
    width: 100%;
    .PasswordInput {
      margin-top: 20px;
      .MuiOutlinedInput-root {
        display: contents;
      }
    }
    .validation-icon {
      font-size: 1rem;
      margin-right: 10px;
      color: #e9e9e9;
      &.checked {
        color: #30b131;
      }
    }
  }
  .MuiGrid-root,
  .QuestionHeader {
    width: 100%;
  }
  img {
    max-width: 150px;
  }
}

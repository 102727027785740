.QuestionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .MuiTypography-root {
        text-align: center;
    }
    .MuiCircularProgress-root {
        display: none;
    }
    &.is-loading {
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        .MuiCircularProgress-root {
            position: absolute;
            left: 50%;
            margin-top: -20px;
            top: 50%;
            display: initial;
            margin-left: -20px;
            z-index: 100000000;
            & + * {
                opacity: .3;
            }
        }
    }
}
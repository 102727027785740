.CheckPoint {
    max-width: initial;
    justify-content: center;
    display: flex;
    flex: 1;
    text-align: center;
    .MuiButton-root {
        margin-top: 20px;
    }
    .CategoryStepsIndicator {
        position: relative;
        max-width: 800px;
        margin: 0 auto;
    }
    .ImageCover {
        padding-top: 160px;
    }
    @media (max-width: 899px) {
        .vertical-form {
            margin-top: 40px;
        }
    }
    @media (max-width: 600px) {
        .ImageCover {
            padding-top: 350px;
        }
    }
}
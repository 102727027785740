.container_check_d {
  display: flex;
  width: 136px;
  height: 40px;
  position: absolute;
  margin-left: 200px;
}
.text_status_derecha_ok {
  background-color: #ebffee;
  border-color: #ebffee;
  color: #42a899;
  /* position: absolute;
    margin-left: 235px; */
  height: 20px;
  width: 95px;
  font-family: sans-serif;
  text-align: center;
  border-radius: 10px 40px 40px 10px;
  margin-top: 10px;
  margin-left: -6px;
}

.img_check_derecha {
  position: absolute;
  /* margin-left: 120px; */
}

.status {
  width: 40px;
  height: 40px;
}

@media (max-width: 874px) {
  .container_check_d {
    display: flex;
    width: 35px;
    height: 35px;
    position: absolute;
    margin-left: 100px;
  }

  .status {
    width: 35px;
    height: 35px;
  }
}

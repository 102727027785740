.WhatsappButton {
    background: transparent;
    border: none;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
    .MuiFab-root {
        background: white;
        .MuiSvgIcon-root {
            color: #4CAF50
        }
    }
    .MuiButtonBase-root {
        font-size: 0;
    }
}

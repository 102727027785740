.MoreProducts {
    h6 {
        margin-bottom: 20px;
    }
    .MuiGrid-item {
        text-align: center;
        img {
            width: 100%;
        }
        .MuiTypography-body1 {
            margin: 10px 0;
        }
    }
}
.CenteredMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    padding: 40px 0;
    min-height: calc(100vh - 180px);
    max-width: 400px;
    margin: auto;
    img {
        width: 100%;
        max-width: 300px;
        margin-bottom: 20px;
    }
}
.Footer {
    background-color: #323E48;
    color: #FFFFFF;
    padding: 40px;
    .logo-img {
        max-width: 127px;
        width: 100%;
        height: auto;
    }
    a {
        color: white;
    }
    .MuiTypography-root {
        font-size: 16px;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .MuiTypography-h6 {
        // text-decoration: underline;
    }
    .items-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        min-height: 30vh;
    }
    .MuiCheckbox-root {
        &:not(.Mui-checked) {
            color: white;
        }
    }
    .MuiOutlinedInput-input {
        background-color: white;
    }
    .icons {
        a {
            &:not(:last-child) {
                margin-right: 25px;
            }
            svg {
                font-size: 24px;
                color: white;
            }
        }
    }
    ul {
        padding: 0;
        margin-top: 5px !important;
        margin-bottom: 20px !important;
        li {
            list-style: none;
            margin-bottom: 5px;
        }
    }
    .stop {
        width: 233px;
    }
    @media (max-width: 899px) {
        .icons {
            margin: 20px auto;
        }
        img {
            margin: auto;
        }
    }
    @media (max-width: 600px) {
        a {
            min-height: 48px;
            display: flex;
            align-items: center;
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@300;400;700&display=swap');

@import url('./Card.scss');
@import url('./Checkbox.scss');
@import url('./Form.scss');
@import url('./Button.scss');
@import url('./Input.scss');
@import url('./Text.scss');
@import url('./LinearProgress.scss');

* {
    font-family: Montserrat, sans-serif;
}
.ItemDoneList {
  display: flex;
  margin-bottom: 8px;
  .icon-wrapper {
    margin-right: 8px;
    display: flex;
    align-items: center;
    img {
      width: 24px;
    }
    .not-done {
      width: 24px;
      height: 24px;
      background-color: #E9E9E9;
      border-radius: 50%;
    }
  }
}

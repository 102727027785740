.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #42a898;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.floating-button:hover {
  background-color: #007bff;
}

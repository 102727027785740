.carrusel_container {
  /* display: flex; */
  /* flex-wrap: nowrap;    */
  /* justify-content: center; */
  /* background-color: red; */
  text-align: center;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 20px;
  overflow-x: auto;
  white-space: nowrap;
}

.HowTo {
    h6 {
        margin-bottom: 20px;
    }
    .MuiGrid-item {
        text-align: center;
        img {
            height: 100px;
        }
        .MuiTypography-body1 {
            margin: 10px 0;
        }
    }
    .MuiButton-root {
        margin: 20px auto 40px;
        display: table;
    }
}
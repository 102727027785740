.ProductSlider {
    .MuiTypography-h5 {
        color: #87D1E6;
    }
    ul {
        margin: 20px 0;
        padding: 0;
        padding-left: 15px;
    }
    .Carousel {
        max-width: 400px;
        margin-left: -100px;
    }
    .carousel-wrapper {
        position: relative;
        top: 0;
        padding: 30px 0;
        .background {
            background: #87d1e6;
            position: absolute;
            height: 100%;
            width: 200vw;
            left: 100px;
            border-radius: 60px;
            top: 0;
        }
        .carousel.carousel-slider, .slider-wrapper {
            overflow: visible;
        }
        .slide {
            transition: all 500ms ease;
            &:not(.selected) {
                transform: scale(.8);
                opacity: .7;
            }
        }
    }
    .description-wrapper {
        position: relative;
        padding-right: 100px;
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%);
        z-index: 1;
        padding-top: 60px;
        &::before {
            content: "";
            background-color: white;
            position: absolute;
            right: 50%;
            top: 0;
            width: 100vw;
            height: 100%;
            z-index: -1;
        }
    }
    @media (max-width: 1199px) {
        .MuiGrid-container {
            flex-direction: column-reverse;
        }
        .Carousel {
            margin: auto;
        }
        .carousel-wrapper{
            .background {
                left: 50%;
            }
        }
    }
}

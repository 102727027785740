.MuiInputBase-root {
    &.FixedInputPlaceholder {
        &::before {
            content: attr(data-placeholder);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 14px;
            color: #a9aeb2;
            z-index: 1;
            background: white;
        }
        &.Mui-focused, &.filled, &:focus, &:active, &:hover {
            &::before {
                content: initial;
            }
        }
    }
}
.Blog {
    iframe {
        border: none;
        width: 100%;
        min-height: 100vh;
    }
}
.Layout.blog {
    .view-wrapper {
        padding: 0;
    }
}
.PaymentForm {
    max-width: 1000px;
    margin: auto;
    &.vertical-form {
        padding-bottom: 40px;
        h5.MuiTypography-root {
            text-align: left;
            margin-bottom: 20px;
        }
        .MuiFormControl-root {
            width: 100%;
        }
    }
    .MuiButton-root {
        align-self: center;
    }
    .delivery-icon {
        margin: 20px 0;
        & + p {
            margin-bottom: 40px;
        }
    }
    a {
        color: inherit;
    }
    .resume-card {
        margin-top: 20px;
        .MuiTypography-root.MuiTypography-h6 {
            text-align: left;
            margin-bottom: 20px;
        }
        .MuiGrid-container {
            .MuiGrid-root {
                margin-bottom: 10px;
            }
        }
    }
    .MuiAlert-root {
        margin-top: 20px;
    }
    .MuiSelect-outlined {
        padding-top: 25px;
    }
    .MuiInputLabel-root {
        margin-top: 4px;
        &.MuiFormLabel-filled, &.Mui-focused {
            margin-top: 15px;
        }
    }
    .payment-methods {
        margin-top: 20px;
        text-align: center;
        img {
            max-height: 60px;
            margin: 0 10px;
        }
        .logos {
            display: flex;
        }
    }
    @media (max-width: 900px) {
        .sticky-wrapper {
            & > div {
                & > div {
                    position: relative !important;
                    padding: 0 !important;
                    transform: none !important;
                    top: 0 !important;
                    left: 0 !important;
                }
            }
        }
    }
}

.Layout.payment {
    .Footer {
        display: none;
    }
    .Header .options {
        display: none;
    }
}
.SingleImageOptionSelector {
    display: flex;
    .option-wrapper {
        margin-top: 20px;
        justify-content: space-evenly;
    }
    .option {
        cursor: pointer;
        img {
            max-width: 200px;
            &:not(.disabled) {
                display: none;
            }
        }
        &:hover, &.selected {
            img {
                &:not(.disabled) {
                    display: initial;
                }
                &.disabled {
                    display: none;
                }
            }
        }
    }
    .MuiButton-root {
        margin-top: 20px;
    }
}
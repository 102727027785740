.PasswordInput {
    width: 100%;
    position: relative;
    .MuiInputBase-root {
        width: 100%;
    }
    .MuiSvgIcon-root {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        cursor: pointer;
    }
}
.Dashboard {
    .MuiTypography-h5 {
        margin: 20px 0;
    }
    .Card-title {
        margin-bottom: 20px;
        width: 100%;
    }
    img[alt="logo"] {
        width: 100px;
    }
    .price-input {
        margin-bottom: 20px;
    }
}
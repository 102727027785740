.ProfilePanel {
  padding: 20px 0px;
  .plan-end {
    text-align: center;
    margin-bottom: 20px;
  }
  &.hasPlan {
      .CustomerService {
      margin-top: 40px;
    }
  }
  @media (max-width: 900px) {
      .sticky-wrapper {
          & > div {
              & > div {
                  position: relative !important;
                  padding: 0 !important;
                  transform: none !important;
                  top: 0 !important;
                  left: 0 !important;
              }
          }
      }
  }
}

.Sign-in {
  min-height: calc(100vh - 180px);
  .wrapper-back-button {
    position: absolute; 
    padding: 10px;
  }
  .MuiGrid-root {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    .wrapper-section {
      width: 300px;
      .image-top {
        width: 150px;
        padding-top: 20px;
      }
      .MuiButton-root {
        margin: 0px 40px 20px;
      }
      .wrapper-redirect {
        display: flex;
        flex-direction: column;
        .button-outlined {
          color: #FFA489;
          border-color: #FFA489;
        }
        .MuiTypography-root {
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .image-right {
    filter: brightness(50%);
    width: 100%;
    height: 90vh;
    object-fit: cover;
    border: none;
  }
  @media (max-width: 899px) {
    .grid-right {
      display: none;
    }
    .wrapper-back-button {
      display: none;
    }
  }
}

.Layout.sign-in {
  .circle.green-top {
      display: initial;
  }
  .view-wrapper {
      padding-top: 0;
      &::before {
          top: 500px;
      }
      .circle.green-top {
          top: 500px;
      }
  }
}

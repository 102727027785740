.AboutUs {
    padding-bottom: 40px;
    max-width: 1200px;
    margin: auto;
    h4, h5 {
        margin: 10px 0;
        text-align: center;
    }
    h5 {
        & + h4 {
            margin-top: 30px;
        }
    }
    .TopCover {
        .data-wrapper {
            max-width: 100%;
            padding: 40px 100px;
            .MuiTypography-root {
                text-align: left;
            }
        }
        .background-image {
            left: 0;
            transform: none;
            width: 100%;
            height: auto;
        }
    }
    .MuiGrid-container {
        & + h5 {
            margin-top: 80px;
        }
        &.grid-item {
            .MuiGrid-item {
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                img {
                    width: 100%;
                }
            }
        }
    }
    .MuiCard-root {
        text-align: center;
        background: transparent;
        box-shadow: none;
        border-radius: 64px !important;
        cursor: default;
        padding: 50px 20px;
        transition: 1s transform ease;
        &:hover {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 8px 16px 30px #9F9FA23D;
            transform: scale(1.05);
        }
        img {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    .description {
        margin-top: 20px;
        display: block;
    }
    .experts-info {
        max-width: 600px;
        margin: 20px auto 0;
        h4 {
            margin: 40px 0 20px;
        }
        h5 {
            margin-bottom: 20px;
        }
        button {
            margin: 40px auto 0;
            display: block;
        }
    }
    @media (max-width: 899px) {
        .TopCover {
            .data-wrapper {
                padding: 100px;
            }
        }
    }
    @media (max-width: 753px) {
        .TopCover {
            .background-image {
                height: 100%;
                width: auto;
            }
        }
    }
    @media (max-width: 500px) {
        .TopCover {
            .data-wrapper {
                padding: 40px;
            }
        }
    }
}
.Layout.about-us {
    .view-wrapper {
        padding-top: 0;
    }
}
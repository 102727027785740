.ClosedQuestion{
    align-items: center !important;
    justify-content: center !important;
    .MuiTypography-body1 {
        margin: 20px 0;
        text-align: center;
    }
    .MuiTypography-h6 {
        text-align: center;
    }
    .options {
        width: 100%;
        max-width: 300px;
    }
}
.ProgressBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    .MuiLinearProgress-root {
        width: 100%;
    }
    label {
        margin-bottom: 10px;
        color: #87E1D1;
    }
}

.Modal {
    background: white;
    max-width: 400px;
    margin: auto;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 40px;
    border-radius: 25px;
    text-align: center;
    .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
    }
}

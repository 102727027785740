body {
  margin: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'results';
  src: url('./assets/fonts/Omnes-Bold.otf') format('bold'),
    url('./assets/fonts/omnes-regular-webfont.ttf') format('regular')
      url('./assets/fonts/Omnes-Semibold.ttf') format('semibold')
      url('./assets/fonts/omnes_medium-webfont.ttf') format('medium');
}

@font-face {
  font-family: 'result_bold';
  src: url('./assets/fonts/Omnes-Bold.otf') format('bold');
}

@font-face {
  font-family: 'Omnes';
  src: url('./assets/fonts/Omnes-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Omnes_semibold';
  src: url('./assets/fonts/Omnes-Semibold.ttf') format('opentype');
}

@font-face {
  font-family: 'Omnesb';
  src: url('./assets/fonts/omnes-regular-webfont.ttf') format('truetype');
}

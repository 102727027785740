.container_alert_i {
  display: flex;
  width: 136px;
  height: 40px;
  position: absolute;
  margin-left: -200px;
}

.text_status_izquierda_alert {
  background-color: #ed616d;
  border-color: #ed616d;
  color: #ffffff;
  /* position: absolute; */
  /* margin-left: -235px; */
  height: 20px;
  width: 95px;
  font-family: sans-serif;
  text-align: center;
  border-radius: 40px 10px 10px 40px;
  margin-top: 10px;
  margin-right: -10px;
}

.img_check_izquierda {
  position: absolute;
  /* margin-left: -120px; */
}

.status {
  width: 40px;
  height: 40px;
}

@media (max-width: 874px) {
  .container_alert_i {
    display: flex;
    width: 35px;
    height: 35px;
    position: absolute;
    margin-left: -100px;
  }
  .status {
    width: 35px;
    height: 35px;
  }
}

.footer-container {
    background-color: #06206a;
    width: 100%;
    height: 177px;
    color: white;
  
    .redes {
      display: inline-block;
      vertical-align: top;
  
      ul {
        display: inline-block;
        padding-left: 0;
        margin-bottom: 0;
        vertical-align: top;
        list-style: none;
  
        li {
          margin: 0 5px;
          float: left;
        }
      }
    }
  
    .logos {
      width: 35px;
      height: 35px;
    }
  
    footer {
      p {
        color: #fff;
        font-family: "Arial";
        font-size: 13px;
        margin: 0;
      }
  
      .by {
        max-width: 90px;
        margin-left: 15px;
        display: inline-block;
        vertical-align: top;
      }
  
      hr {
        border-top: 1px solid #6e7ea9;
        max-width: 80%;
        margin: 20px auto;
        display: block;
      }
    }
  }
  
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  
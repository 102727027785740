.SingleOptionSelectorColumn {
    width: 100%;
    figure {
        margin: 20px auto;
        display: table;
    }
    .MuiButton-root.MuiButton-contained {
        width: 100%;
        text-transform: none;
        margin-top: 20px;
        line-height: 18px;
        min-height: 36.5px;
        border-radius: 20px;
        max-width: 400px;
    }
}

.ColumnSelecctorNewResults{
    grid-template-columns: 200px 200px 200px;
    display: grid;
    margin: 0.5rem;
    grid-column-gap: 0.5rem;
}
.Layout {
    overflow-x: hidden;
    .view-wrapper, .Breadcrumb {
        max-width: 1500px;
        margin: auto;
    }
    .Breadcrumb {
        padding: 5px 20px;
    }
    .view-wrapper {
        padding: 10px 10px 0;
        position: relative;
        &::before, &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 50%;
            z-index: -10;
            left: 0;
        }
        &::before {
            top: 0;
            background: #E5FCF8 linear-gradient(202.5deg, #E5FCF8 0%, white 50%) 0% 0% no-repeat padding-box;
        }
        &::after {
            bottom: 0;
            background: #E5FCF8 linear-gradient(22.5deg, #E5FCF8 0%, white 50%) 0% 0% no-repeat padding-box;
        }
        .circle-original {
            display: none;
            position: absolute;
            &.green-top, &.white-top {
                right: 0;
                top: 0;
                width: 220px;
            }
            &.green-bottom, &.white-bottom {
                left: 0;
                bottom: 0;
                width: 350px;
            }
        }
    }
}
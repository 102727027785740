.Assessment {
    display: flex;
    justify-content: center;
    flex: 1;
    .BackButton {
        position: absolute;
        left: 20px;
        top: 20px;
    }
    .form-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        .MuiFormGroup-root {
            width: 100%;
        }
        > *:not(.SicknessWarning) {
            max-width: 600px;
        }
    }
}
.Layout.assessment {
    background: #323E48;
    .Footer {
        display: none;
    }
    .Header .options {
        display: none;
    }
    .form-wrapper {
        color: white;
        padding: 20px 0;
        min-height: calc(100vh - 140px);
        display: flex;
    }
    .circle.white-top, .circle.white-bottom {
        display: initial;
    }
    .WhatsappButton {
        display: none;
    }
    @media (max-width: 460px) {
        .form-wrapper {
            padding-top: 50px;
        }
    }
}
.NutritionExperts {
    img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    .experts-image {
        padding-right: 30px;
        img {
            border-radius: 0 0 30px 30px;
        }
    }
    .vidarium-wrapper {
        padding: 0 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .logo {
            max-width: 180px;
            margin-bottom: 20px;
        }
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    @media (max-width: 1199px) {
        .experts-image {
            max-width: 500px;
            margin: auto;
            padding: 0;
        }
        .vidarium-wrapper .logo {
            margin: 40px auto;
        }
    }
}

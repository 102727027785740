.SetPassword {
    .MuiFormGroup-root {
        width: 100%;
        .validation-icon {
            font-size: 1rem;
            margin-right: 10px;
            color: #e9e9e9;
            &.checked {
                color: #30b131;
            }
        }
    }
    .MuiGrid-root, .QuestionHeader {
        width: 100%;
    }
    img {
        max-width: 150px;
    }
    .MuiButton-textPrimary {
        width: 100%;
    }
    .update-email-wrapper {
        margin: 20px auto;
        justify-content: center;
        display: flex;
        flex-direction: column;
        .MuiTypography-root {
            font-weight: 500;
        }
        .MuiButton-root {
            text-decoration: underline;
            margin-top: 0;
        }
    }
}
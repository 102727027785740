.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 5rem;
}

.chart {
  position: relative;
}

.chart-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.percentage {
  font-size: 24px;
  font-weight: bold;
}

.label {
  font-size: 14px;
}

.contenedorPercent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 3rem;
  height: 3rem;
  gap: 2%;
  border-radius: 50%;
  border-color: #42a898;
  background-color: #42a898;
  color: #ffffff;
  font-size: xx-large;
  font-family: 'Omnes_semibold', sans-serif;
}

.Stepper {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    .BackButton {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 20;
    }
    .component-wrapper {
        flex-direction: column;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        &.hide-buttons {
            .MuiButton-root:not(.simulate-button) {
                visibility: hidden;
                margin-top: -15px;
            }
            .MuiCircularProgress-root {
                display: none;
            }
        }
        &.is-loading {
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
            .QuestionWrapper .MuiCircularProgress-root + * {
                opacity: .3;
            }
        }
    }
    .simulate-button {
        margin: auto;
        display: table;
        .MuiCircularProgress-root {
            display: initial;
        }
    }
}
.MinuteCounter {
    background: #c1c1c1;
    box-shadow: 0px 3px 6px rgb(15 34 31 / 11%);
    border-radius: 16px;
    padding: 5px 0;
    .MuiIconButton-root {
        color: white;
        padding: 5px;
    }
    .MuiGrid-root {
        margin-top: 0;
    }
    .MuiOutlinedInput-root {
        margin: 0;
        max-width: 70px;
        .MuiOutlinedInput-notchedOutline {
            border: none;   
        }
        .MuiOutlinedInput-input {
            text-align: center;
            background: white;
            padding: 2px 10px;
        }
    }
    .MuiGrid-item {
        padding-top: 0 !important;
    }
}
.PhoneNumberInput {
    position: relative;
    &, .MuiOutlinedInput-root {
        width: 100%;
    }
    .MuiTypography-caption {
        position: absolute;
        z-index: 1;
        color: #323E48;
        left: 14.5px;
        top: 5px;
    }
    &:not(.filled) {
        .MuiTypography-caption {
            display: none;
        }
    }
    &.filled {
        input {
            padding-top: 25px;
        }
    }
}
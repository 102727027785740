.MultipleImageOptionSelector {
    display: flex;
    min-width: 520px;
    .option {
        cursor: pointer;
        img {
            max-width: 100%;
            height: 80px;
            margin: 20px auto 0;
            &:not(.disabled) {
                display: none;
            }
        }
        &:hover, &.checked {
            img {
                &:not(.disabled) {
                    display: initial;
                }
                &.disabled {
                    display: none;
                }
            }
        }
    }
    .MuiFormControl-root {
        width: 100%;
        margin: auto;
        max-width: 370px;
        margin-top: 20px;
    }
    .MuiTextField-root {
        max-width: 100%;
    }
    .MuiGrid-root {
        margin-top: 10px;
        display: flex;
        .MuiPaper-root {
            display: flex;
            flex-direction: column;
            position: relative;
            flex: 1;
            .MuiCheckbox-root {
                position: absolute;
                left: 0;
                top: 0;
            }
            .MuiTypography-root {
                margin-bottom: 0;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .MuiButton-root {
        margin-top: 20px;
    }
    .tooltip-icon {
        color: #FFA489;
        position: absolute;
        top: 9px;
        right: 9px;
    }
    .MuiOutlinedInput-root {
        margin-left: 17px;
    }
    @media (max-width: 600px) {
        .MuiGrid-root .MuiPaper-root {
            flex-direction: row;
            padding-left: 40px;
            align-items: center;
            .MuiTypography-root {
                justify-content: flex-start;
                text-align: left;
                margin: 0;
            }
            .MuiCheckbox-root {
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .option img {
            height: 30px;
            margin: 0;
            margin-right: 10px;
        }
    }
    @media (max-width: 570px) {
        & {
            min-width: calc(100vw - 40px);
        }
    }
    @media (max-width: 500px) {
        .MuiGrid-root .MuiPaper-root {
            padding: 10px;
            padding-left: 40px;
        }
    }
    @media (max-width: 400px) {
        .option {
            min-width: 100%;
        }
    }
}
.Category {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    .top-wrapper {
        width: 100%;
        margin-bottom: 15px;
        .ProgressBar {
            width: 100%;
            max-width: 300px;
            margin: auto;
        }
        .MuiPaper-root {
            margin: 20px 0;
        }
    }
    .Stepper {
        justify-content: center;
    }
}
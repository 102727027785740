.ProductPack {
    max-width: 200px;
    position: relative;
    img {
        width: 100%;
    }
    .MuiTypography-caption {
        max-width: 100px;
        display: table;
    }
    .customized-wrapper {
        position: absolute;
        top: 60px;
        left: 25px;
    }
    .recomendations {
        max-width: 95px;
        margin-top: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
        display: flex;
        flex-direction: column;
        .MuiTypography-root {
            position: relative;
            margin-bottom: 10px;
            font-weight: bold;
            opacity: .7;
            width: fit-content;
            &::after {
                content: "";
                background: #a5a1db;
                height: 10px;
                width: calc(100% + 4px);
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
                padding: 0;
                margin-left: -2px;
            }
        }
    }
}
.CustomerService {
  box-shadow: 0px 16px 30px #9f9fa23d !important;
  border-radius: 20px;
  opacity: 1;
  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 300px;
    }
    hr {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

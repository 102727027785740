.ImageCover {
    max-width: initial;
    padding-top: 200px;
    .image-wrapper {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        text-align: center;
        background: white;
        padding: 20px 0 0;
        img {
            height: 200px;
            z-index: 1;
            position: relative;
        }
        .background-decoration {
            position: absolute;
            right: 0;
            top: 0;
        }
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: #E5FCF8 linear-gradient(225deg, #E5FCF8 0%, white 50%) 0% 0% no-repeat padding-box;
        }
    }
    @media (max-width: 840px) {
        .image-wrapper {
            padding: 20px 20px 0;
            width: calc(100% - 40px);
        }   
    }
}
.MultipleOptionSelector {
    width: 100%;
    figure {
        margin: 20px auto;
        display: table;
    }
    .MuiGrid-root {
        .MuiButton-root {
            width: 100%;
            text-transform: none;
            justify-content: flex-start;
            line-height: 18px;
            min-height: 36.5px;
            border-radius: 27px;
        }
        & > .MuiButton-root {
            margin-top: 20px;
            text-align: left;
            max-width: 300px;
        }
    }
    .MuiButton-root:not(.white) {
        margin: auto;
        display: table;
        margin-top: 20px;
    }
}
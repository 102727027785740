.InProgress {
    .MuiGrid-root, .QuestionHeader {
        width: 100%;
    }
    img {
        max-width: 150px;
    }
    .MuiCircularProgress-root {
        margin-top: 20px;
    }
}
.MuiInputBase-root {
    border-radius: 16px !important;
    .MuiOutlinedInput-input {
        border-radius: 16px;
        background: white;
    }
    .MuiOutlinedInput-notchedOutline {
        border-radius: 16px;
    }
    &.FixedInputPlaceholder {
        &::before {
            content: attr(data-placeholder);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 14px;
            color: #a9aeb2;
            z-index: 1;
            background: white;
        }
        &.Mui-focused, &.filled {
            &::before {
                content: initial;
            }
        }
    }
}
.UserInfoForm {
    width: 100%;
    .MuiCheckbox-root {
        color: white;
    }
    .MuiTypography-h6 + .MuiTypography-body1 {
        margin-top: 10px;
        text-align: center;
    }
}

.ClientTestimonials {
    .client-wrapper {
        position: relative;
        padding-right: 200px;
        padding-bottom: 100px;
        img.client {
            max-width: 58%;
            position: absolute;
            right: 10px;
            bottom: 0;
            height: auto;
        }
    } 
    .MuiTypography-root {
        margin: 10px 0;
        max-width: 480px;
    }
    .call-to-action {
        .MuiButton-root {
            margin-top: 30px;
        }
    }   
    ul {
        margin: 20px 0;
        padding: 0;
        li {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img {
                max-width: 45px;
                margin-right: 20px;
                height: auto;
            }
        }
    }
    @media (max-width: 1199px) {
        & {
            padding-bottom: 40px;
            .client-wrapper {
                position: relative;
                padding: 0;
                padding-bottom: 200px;
                margin-bottom: 40px;
                &::after {
                    content: "";
                    border-bottom: 1px solid;
                    width: 85%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                img.client {
                    max-width: 300px;
                }
            }
        }
    }
    @media (max-width: 950px) {
        .client-wrapper {
            padding-bottom: 280px;
        }
    }
    @media (max-width: 430px) {
        .client-wrapper{
            img.client {
                max-width: 250px;
            }
        }
    }
    @media (max-width: 360px) {
        .client-wrapper{
            img.client {
                max-width: 200px;
            }
        }
    }
}

.CategoryStepsIndicator {
    img.icon {
        height: 40px;
        max-width: initial;
    }
    .cover-image {
        margin-top: 40px;
    }
    @media (max-width: 600px) {
        .MuiStepConnector-root {
            display: none;
        }
        .MuiStepper-root {
            align-items: flex-start;
            flex-direction: column;
        }
        .MuiStep-root {
            margin: 20px 0;
        }
    }
}
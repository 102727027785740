.card-title-recommendations {
  text-align: left;
  font: normal normal bold 1rem/1rem Omnes;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: 'Omnes', sans-serif;
  font-weight: bolder;
  margin-top: 0.2rem;
  margin-left: 0.3rem;
}

.card-container-recommendations-title {
  display: flex;
  align-items: center; /* Alinea verticalmente los elementos */
  margin-bottom: 1rem;
}

.card-icon {
  flex: 0 0 auto; /* Permite que el ancho del div se ajuste al contenido */
  margin-right: 10px; /* Espacio entre el icono y el título */
  width: 2rem;
  height: 2rem;
}

.card-title {
  flex: 1; /* Permite que el título ocupe todo el espacio restante */
  margin-top: 2px;
  /* margin-left: 0.7rem; */
}

/* Responsive cols */

.container-cols-recommendations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* width: 100%; */
}

.column-cols-recommendations {
  /* flex: 0 0 calc(33.33% - 25px); */
  /* margin: 1rem; */
  /* padding: 20px; */
  /* background-color: #f0f0f0; */
  /* border: 1px solid #ccc; */
  /* display: flex; */
  flex-wrap: wrap;
}

/* @media (max-width: 768px) {
  .column-cols-recommendations {
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .column-cols-recommendations {
    flex: 0 0 calc(100% - 20px);
  }
} */

.card-container-recommendations-title-font {
  font-size: 12px !important;
}

.box1-recomendations {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

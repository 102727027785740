.PlanList {
    .plan-item {
        background-color: #07231F !important;
        color: white !important;
        flex: 1;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        hr {
            width: 100%;
        }
        .call-to-action {
            text-align: center;
            margin-top: 80px;
            justify-content: center;
            .MuiButton-root {
                margin-top: 40px;
            }
        }
        .MuiTypography-h4 {
            text-align: center;
        }
    }
    .MuiGrid-item {
        display: flex;
        flex: 1;
    }
}
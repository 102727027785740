.AccountByConfirm {
  height: 80vh;
  padding-top: 60px;
  display: flex;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .MuiTypography-root {
    max-width: 340px;
  }
  .title {
    margin: 20px 0px;
    font-size: 24px;
  }
}

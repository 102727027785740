.DiscountCode {
    .MuiTypography-root {
        text-align: left;
        font-weight: 500;
        margin: 20px 0 10px;
        display: block;
    }
    .input-wrapper {
        position: relative;
        .MuiOutlinedInput-root {
            width: 100%;
        }
        .MuiButton-root {
            margin-top: 0;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }
    }
}

.SicknessWarning {
    max-width: 900px;
    .MuiTypography-body1 {
        margin: 20px 0;
    }
    .title {
        font-weight: bold;
        margin-bottom: 10px;
    }
    .MuiButton-root {
        display: block;
        margin: 40px auto 0;
    }
    img {
        max-width: 100%
    }
    .text-wrapper {
        padding-left: 20px;
    }
    @media (max-width: 1060px) {
        & {
            margin-top: 40px;
        }
    }
}
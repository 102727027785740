.PlanApproach {
  .card-plan {
    box-shadow: 0px 16px 30px #9F9FA23D;
    border-radius: 20px;
    opacity: 1;
    .header-text {
      font-size: 24px;
      font-weight: 600;
    }
    .title {
      font-size: 20px;
    }
    .subtitle {
      font-size: 16px; 
    }
    .mt-2 {
      margin-top: 20px;
    }
    .mb-2 {
      margin-bottom: 20px;
    }
  }
}

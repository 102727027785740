.cprincipal {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
  margin-bottom: 7px;
  margin-top: 7px;

  text-justify: auto;
  font: normal normal 0.9rem/0.9rem 'Omnesb';
}

.box_info {
  flex: 1 1 auto;
}
.box_info.box_info_1 {
  width: 46rem;
  background-color: #ffe5ec;
}

.box_info.box_info_1,
.box_info.box_info_2 {
  border-radius: 15px;
  box-shadow: 0 3px 6px #272d3b33;
  margin-left: 1%;
  padding: 1rem;
  font: normal normal 0.9rem / 0.9rem Omnesb;
  text-align: justify;
  margin-top: 1rem;
}

.box_info.box_info_2 {
  background-color: #d8e2dc;
  width: 26rem;
  padding: 1rem;
  font: normal normal 0.9rem / 0.9rem Omnesb;
  font-family: Omnesb, sans-serif;
  text-align: justify;
  margin-top: 1rem;
}

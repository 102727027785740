.ApproachSelection {
    h5 {
        text-align: center;
        margin-bottom: 30px;
    }
    .icon {
        width: 45px;
        height: 45px;
        margin-right: 20px;
    }
    .MuiFormControl-root {
        display: inline;
    }
    .product-checkbox .MuiTypography-root {
        display: flex;
        align-items: center;
    }
    .product-checkbox {
        margin-bottom: 20px;
    }
    .drink {
        margin-bottom: 50px;
    }
    .disclaimer {
        margin: 20px 0;
    }
    .main-product {
        margin: 20px 0;
    }
    .MuiAlert-root {
        margin: 40px 0;
    }
    .product-wrapper {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .service-plans {
        max-width: 1000px;
        margin: 140px auto 0;
        padding-bottom: 30px;
    }
    .Cover {
        .MuiTypography-body1 {
            font-size: 20px;
            font-weight: 500;
        }
    }
}
.Layout.approach-selection {
    .Footer {
        display: none;
    }
    .Header .options {
        display: none;
    }
}
.MainProfile {
  min-height: 100vh;
  max-width: 1200px;
  padding-bottom: 20px;
  margin: auto;
  .tabs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .MuiTypography-root {
      font-size: 40px;
    }
  }
  @media (max-width: 800px) {
    .tabs-wrapper {
      display: block;
    }
  }
}

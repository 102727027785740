.HealthMetric {
    .metric-text {
        font-size: 14px;
    }
    .value-text {
        font-size: 16px;
        color: #539BB0;
    }
    .image-wrapper {
        img {
            max-width: 50px;
            width: 100%;
        }
    }
}
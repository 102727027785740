.principal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 560px;
  width: 680px;
  /* background-color: blue; */
  margin: 0 auto;
}

.cricle-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 420px;
  height: 420px;
  /* gap: 2%; */
  border-radius: 50%;
  border-color: #26367e;
  border-style: dashed;
  /* background-color: #FAFA; */
  /* margin-left: 6%; */
}

.horizontal,
.lateral,
.central,
.subdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'result_bold', sans-serif;
}

.horizontal {
  width: 100%;
  height: 25%;
  margin-top: -8%;
  margin-bottom: -8%;
}
.lateral {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
  /* height: 90%; */
  /* background-color: #FF0000;
  margin-left: -5%; */
}
.central {
  width: 40%;
  height: 86%;
  /*border-color: #FFFFFF;*/
  /*border-style: dashed;*/
}
.subdiv {
  width: 100%;
  height: 50%;
  margin-top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction:column; */
  font-family: Arial, sans-serif;
}

.izquierda {
  margin-right: 6%;
}
.derecha {
  margin-left: 4%;
}

.circle-horizonral {
  width: 26%;
  height: 100%;
  background-color: #c4e4ef;
  border-radius: 50%;
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
}

.circle-vertical {
  width: 100%;
  height: 57%;
  background-color: #c4e4ef;
  border-radius: 50%;
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
}

.item {
  width: 60px;
  height: 60px;
}

.imghuman {
  width: 120px;
  height: 250px;
}

.text_item {
  position: relative;
  text-align: center;
  font-size: small;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

@media (max-width: 874px) {
  .principal {
    height: 400px;
    width: 400px;
  }

  .cricle-container {
    width: 320px;
    height: 320px;
    margin-right: 2%;
    margin-left: 0;
  }
  .text_status_derecha_ok,
  .text_status_derecha_alert {
    width: 0;
    display: none;
  }

  .text_status_izquierda_ok,
  .text_status_izquierda_alert {
    width: 0;
    display: none;
  }
  .item {
    width: 40px;
    height: 40px;
  }
  .status {
    width: 25px;
    height: 25px;
  }
  .imghuman {
    width: 100px;
    height: 200px;
  }
}

@media (max-width: 360px) {
  .principal {
    height: 350px;
    width: 350px;
  }

  .cricle-container {
    width: 250px;
    height: 250px;
    margin-right: 2%;
    margin-left: 0;
  }
  .text_status_derecha_ok,
  .text_status_derecha_alert {
    width: 0;
    display: none;
  }

  .text_status_izquierda_ok,
  .text_status_izquierda_alert {
    width: 0;
    display: none;
  }
  .item {
    width: 40px;
    height: 40px;
  }
  .status {
    width: 25px;
    height: 25px;
  }
  .imghuman {
    width: 100px;
    height: 200px;
  }
}

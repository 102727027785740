.Header {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    /* UI Properties */
    box-shadow: 0px 3px 6px #07231F1C;
    background-color: white;
    padding: 5px 20px;
    .items-container {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        max-width: 1500px;
    }
    .options {
        display: flex;
        flex: 1;
        justify-content: space-evenly;
        margin: 0 40px;
        & > * {
            align-items: center;
            display: flex;
            cursor: pointer;
            white-space: nowrap;
            text-align: center;
        }
        & > span {
            &.active {
                border-bottom: 3px solid #000;
            }
            a {
                color: inherit;
                text-decoration: none;
            }
        }
    }
    .logo-wrapper {
        margin-right: 40px;
        max-width: 127px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .icon {
        font-size: 20px;
        cursor: pointer;
        color: black;
    }
    .session-icon {
        display: flex;
        color: #323E48;
        text-transform: capitalize;
        align-items: center;
        font-size: 0;
        .icon {
            margin-left: 10px;
        }
        &.active {
            .icon {
                color: #6FCFEB;
            }
        }
    }
    .menu-icon {
        display: none;
    }
    @media (max-width: 1100px) {
        min-height: 75px;
        .logo-wrapper {
            margin-right: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 5px;
        }
        .menu-icon {
            display: initial;
        }
        .options {
            display: none;
        }
        &.menu-open {
            .options {
                display: flex;
                position: absolute;
                flex-direction: column;
                background: white;
                z-index: 1000;
                width: calc(100% - 40px);
                left: 0;
                padding: 20px;
                margin: 0;
                top: 85px;
                border-radius: 0 0 10px 10px;
                & > * {
                    margin: 20px 0;
                }
            }
        }
    }
    @media (max-width: 500px) {
        .session-icon {
            .MuiTypography-body1 {
                display: none;
            }
        }
    }
}
.session-menu {
    .MuiMenuItem-root {
        &, a {
            color: #000;
            text-decoration: none;
        }
    }
}
.Recommendations {
  .MuiTypography-h2 {
    font-size: 30px;
    font-weight: 500;
    margin: 40px 0 20px;
  }
  .MuiTypography-h3 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  img {
    margin-right: 10px;
    width: 40px;
  }
}

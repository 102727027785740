.box {
  flex: 1 1 auto;
  border-radius: 5%;
  align-content: center;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 248px;
  /* height: 475px; */
  background: #ffffff 0% 0% no-repeat padding-box;

  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  display: inline-block;
}

.img_product {
  display: block;
  width: 100%;
  max-width: 248px;
  height: 238px;
  margin: 2% auto 0;
  text-align: center;
}

.img_product img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.title_product {
  max-height: 58px;
  text-align: left;
  font: normal normal 600 22px/28px Omnes;
  color: #484646;
  margin: 10px 4%;
  width: 92%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.price {
  height: 29px;
  text-align: left;
  font: normal normal bold 24px/30px Omnes;
  letter-spacing: 0px;
  color: #26367e;
  opacity: 1;
  margin-left: 4%;
}

.button_buy {
  width: 210px;
  height: 50px;
  background: #26367e 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  color: #ffffff;
  font: normal normal bold Omnes;
  font-size: 20px;
  opacity: 1;
  margin-bottom: 10%;
  margin-top: 10%;
  border-style: none;
}

.button_buy:hover {
  background: #42a898 0% 0% no-repeat padding-box;
}

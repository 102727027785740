.sale_container {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 20px;
  text-align: center;
  align-content: center;
  color: #000000;
  margin-bottom: 40px;
  min-width: 360px;
}

.text {
  text-align: center;
  font: normal normal normal 34px/38px 'Omnesb';
  letter-spacing: 0px;
  color: #000000;
  font-size: x-large;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.flex_button {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  background-color: #f4f7fe;
  opacity: 1;
  justify-content: center;
}

.flex_icon {
  text-align: center;
  align-items: center;
  flex-basis: 25%;
}
.flex_action {
  flex-basis: 75%;
  text-align: center;
  align-items: center;
}

.button_sale {
  width: 50%;
  height: 70px;
  border-radius: 40px;
  border-style: none;
  opacity: 1;
  background: #26367e 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  font: normal normal bold 23px/58px 'Omnes';
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 50px;
  margin-bottom: 1rem;
}
.button_sale:hover {
  background: #42a898 0% 0% no-repeat padding-box;
  cursor: pointer;
}
.label_sale {
  text-align: center;
  font: normal normal bold 25px/38px 'Omnes';
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

@media (max-width: 595px) {
  .button_sale {
    width: 330px;
    height: 50px;
    border-radius: 40px;
    border-style: none;
    opacity: 1;
    background: #26367e 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    font: normal normal bold 23px 'Omnes';
    letter-spacing: 0px;
    color: #ffffff;
    margin-top: 50px;
  }
}

.vertical-form {
    .MuiTypography-root {
        &:not(.MuiTypography-caption) {
            text-align: center;
        }
        & + .MuiFormGroup-root {
            margin-top: 20px;
        }
    }
    .MuiButton-root {
        margin-top: 20px;
    }
    .MuiFormControl-root {
        & + .MuiFormControl-root {
            margin-top: 10px;
        }
        & + .MuiTypography-root {
            margin-top: 10px;
            margin-bottom: 20px;
        }
        .MuiFormControlLabel-label {
            text-align: left;
        }
        & + .MuiPaper-root {
            margin-top: 20px;
        }
    }
}
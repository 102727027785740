.TopCover {
    position: relative;
    max-height: 500px;
    overflow: hidden;
    margin: 0 -40px;
    .data-wrapper {
        z-index: 1;
        max-width: 60%;
        padding-left: 100px;
        flex-direction: column;
        display: flex;
        align-items: flex-start;
        padding-top: 65px;
        padding-bottom: 65px;
        .MuiTypography-root {
            margin: 10px 0;
            color: #273133;
        }
        .MuiTypography-body1 {
            background: #ffffff82;
            padding: 10px;
            border-radius: 10px;
        }
        .MuiButton-root {
            margin-top: 20px;
        }
    } 
    img {
        width: 100%;
        position: absolute;
        z-index: -1;
        height: auto;
    }
    @media (max-width: 1048px) {
        & {
            max-height: initial;
            .data-wrapper {
                padding-left: 40px;
                padding-right: 40px;
                max-width: 100%;
            }
            img {
                width: auto;
                height: 100%;
                position: absolute;
                z-index: -1;
            }
        }
    }
    @media (max-width: 800px) {
        & {
            img {
                left: -30%;
            }
        }
    }
}
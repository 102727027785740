.Carousel {
    position: relative;
    .carousel-status, .control-dots, .thumbs-wrapper {
        display: none;
    }
    .MuiButtonBase-root {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-left: -30px;
        font-size: 0;
    }
    .carousel.carousel-slider {
        padding-bottom: 40px;
    }
    .slider-wrapper {
        & + .MuiButtonBase-root {
            margin-left: 30px;
        }
    }
    img {
        height: auto;
    }
}

.MultipleOptionWithSubItems {
    .SingleOptionSelector {
        background: white;
        border-radius: 0 0 27px 27px;
        padding-top: 29px;
        max-width: 300px;
        margin: -29px auto 0;
        .MuiTypography-h6 {
            color: #07231F;
            font-size: 14px;
            text-align: left;
            padding: 0 16px;
            margin: 0;
        }
        .MuiTypography-body1 {
            display: none;
        }
        .MuiButton-root {
            margin-top: 0;
            text-align: left;
        }
    }
    .SingleOptionSelector .MuiButton-root:hover, .MuiButton-root.checked:hover {
        background-color: white;
        color: #07231F;
        box-shadow: none;
    }
}
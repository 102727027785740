.FormLogin {
  .MuiFormGroup-root {
    margin: 20px 0px 40px;
    .link-underlined {
      margin: 20px 0px;
      color: #ffa489;
      cursor: pointer;
      text-decoration: underline;
    }

    .link-underlined-error {
      color: rgb(255, 114, 114);
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .message-error {
    color: rgb(255, 114, 114);
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left !important;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    strong {
      cursor: pointer;
    }
  }
  .MuiButton-root {
    margin: 0px 40px 20px;
  }
}

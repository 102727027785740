.visibilityValue {
  visibility: hidden;
}

.div-resumen-color {
  background-color: #fff;
  /* padding: 1rem; */
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
  /* width: 90vw; Ancho del elemento relativo al ancho de la ventana */
  max-width: 100%; /* Limita el ancho máximo al 100% de la pantalla */
  width: 99%; /* Limita el ancho máximo al 100% de la pantalla */
  margin: 0 auto; /* Centra el elemento horizontalmente */

  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.header-text {
  float: left;
  /* background-color: red; */
  width: 50%;
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
}

.header-percent {
  float: right;
  /* background-color: #caba25; */
  width: 50% !important;
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
}

.header-resumen {
  top: 59px;
  left: 51px;
  width: 100% auto;
  /* height: 12rem; */
  background: transparent linear-gradient(180deg, #26367e 0%, #1b2655 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 37px;
  opacity: 1;
  padding: 1rem;
  padding-left: 2rem;
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
  display: flex;
}

.header-name {
  top: 125px;
  left: 136px;
  /* width: 194px; */
  /* height: 71px; */
  text-align: left;
  font: normal normal bold 50px/76px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
  opacity: 1;
}

.header-resumen-title {
  text-align: left;
  font: normal normal bolder 1.6rem/2rem 'Omnes';
  letter-spacing: 0px;
  color: #42a898;
  font-family: 'Omnes';
  font-weight: bolder;
  opacity: 1;
}

.header-result {
  /* top: 273px;
  left: 136px;
   height: 83px; */
  /* width: 50%; */
  text-align: left;
  font: normal normal bold 1.2rem/2rem 'Omnes';
  letter-spacing: 0px;
  color: #ffffff;
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
  opacity: 1;
  margin-bottom: 1rem;
}

.header-percent {
  left: 50rem;
  width: 257px;
  opacity: 1;
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
  align-items: center;
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
  padding-top: 3.2rem;
}

.header-percent-container {
  position: relative;
  width: 6rem; /* Ancho del círculo */
  height: 6rem; /* Alto del círculo */
  border-radius: 50%; /* Para hacerlo circular */
  background-color: #42a898; /* Color de fondo del círculo */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Omnesb', sans-serif;
  margin-left: 2rem;
  font-weight: bolder;
}

#header-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -49.4%);
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
  font-size: 2.4rem; /* Tamaño de la fuente del número */
}

.porpose-container {
  margin-top: 1rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;

  /* Layout Properties */
  /* width: 100%; */
  /* height: 6rem; */
  max-height: calc(100vh - 20px);
  /* UI Properties */
  background: #f4f7fe 0% 0% no-repeat padding-box;
  border-radius: 20px;
  font-family: normal normal 2rem/5rem 'result_bold' !important;
  font-weight: bolder;
  padding: 0.5rem;
}

.separator-header-icon {
  width: 5rem; /* Ajusta el ancho de la imagen según sea necesario */
  height: auto; /* Para mantener la proporción de la imagen */
  margin-right: 10px; /* Espacio entre la imagen y el texto */
  font-weight: bolder;
  opacity: 1;
}

.div-resumen-color,
.circle-results-items {
  font-family: normal normal 2rem/5rem 'result_bold' !important;
}

.separator-header {
  font-size: 1.3rem; /* Tamaño de fuente del texto */
  color: #333; /* Color del texto */
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

/* Circle */

.circle-container {
  position: relative;
  width: 100px; /* Tamaño del círculo */
  height: 100px; /* Tamaño del círculo */
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
}

.circle {
  width: 100%;
  height: 100%;
  border: 2px solid #333; /* Borde del círculo interior */
  border-radius: 50%; /* Para hacerlo circular */
  position: relative;
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
}

.outer-circle {
  position: absolute;
  top: -6px; /* Ajusta la posición del círculo exterior */
  left: -6px; /* Ajusta la posición del círculo exterior */
  width: 112px; /* Tamaño del círculo exterior */
  height: 112px; /* Tamaño del círculo exterior */
  border: 6px solid #f0f0f0; /* Borde grueso del círculo exterior */
  border-radius: 50%; /* Para hacerlo circular */
  clip-path: inset(0 30% 0 0); /* Relleno del círculo exterior por porcentaje */
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
}

.container-cards {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card-principal-container {
  display: flex;
  position: relative;
  float: left;
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  justify-content: center;
}

.card-container {
  width: 22rem;
  height: 14rem auto;
  /* background: #2721cf31 0% 0% no-repeat padding-box; */
  border-radius: 40px;
  opacity: 1;
  padding-left: 0.8rem;
  padding-bottom: 1.3rem;
  padding-right: 1.3rem;
  padding-top: 0;
  margin-top: 1rem;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  /* margin: 0.6rem; */
  font-weight: bold;
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
}

.card-title {
  /* UI Properties */
  text-align: left;
  font: normal normal bold 1.4rem/40px 'result_bold';
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: 'Omnes', sans-serif;
  font-weight: bolder;
  /* margin-top: -1rem; */
}

.card-value {
  text-align: left;
  font: normal normal bold 2rem/2rem 'result_bold';
  letter-spacing: 0px;
  color: #000000;
  font-family: 'result_bold', sans-serif;
  font-weight: bolder;
}

.line {
  display: flex;
  /* margin-top: 1rem; */
}
.card-details {
  font-size: 1.4rem;
  margin-left: 0.3rem;
  font-weight: bold;
}

.card-color-1 {
  background-color: #d7feee !important;
  padding-top: 1rem;
}

.card-color-2 {
  background-color: #f4cffc !important;
}

.card-color-3 {
  background-color: #f9ffd3 !important;
}

.description-info {
  font-family: 'Omnesb' !important;
  background-color: #d7b7e1;
  border-radius: 13px;
  font-size: 0.8rem;
  padding: 5px !important;
  width: 100%;
  margin-top: 1rem;
}

.card-subtitle {
  font-family: 'Omnes_semibold' sans-serif;
  border: 1px solid #d0d1c5;
  border-radius: 18px;
  width: 12rem;
  /* height: 2rem;
  padding: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
}

.description-info-v {
  font-family: 'Omnesb' !important;
  background-color: #eef7b8;
  border-radius: 13px;
  font-size: 0.8rem;
  padding: 5px !important;
  width: 100%;
  margin-top: 1rem;
}

.card-list,
.b1 {
  display: flex;
  margin-right: 0.3rem;
}

.b1 {
  width: 5rem;
}

.msg {
  margin-left: 0.2rem;
}

.bar1 {
  width: 20px;
  height: 38px;
  /* UI Properties */
  background: #7265f1 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.bar2 {
  width: 20px;
  height: 38px;
  /* UI Properties */
  background: #cefb6d 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.bar2-2 {
  width: 20px;
  height: 38px;
  /* UI Properties */
  background: #dad427 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.bar3 {
  width: 20px;
  height: 38px;
  /* UI Properties */
  background: #ff8b8b 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

/* Refinar */
.card-color-4 {
  background-color: #edf6f9 !important;
}

.card-subtitlev2 {
  background: #f4c5d1 0% 0% no-repeat padding-box;
  border-radius: 13px;
}

.card-color-5 {
  background-color: #d7feee;
}
.description-info-v2 {
  font-family: 'Omnesb' !important;
  background: #f4c5d1 0% 0% no-repeat padding-box;
  border-radius: 13px;
  font-size: 0.8rem;
  padding: 5px !important;
  width: 100%;
  margin-top: 1rem;
}

.description-info-v3 {
  font-family: 'Omnesb' !important;
  font-size: 0.8rem;
  padding: 5px !important;
  width: 100%;
  height: 4rem;
  margin-top: 1rem;
  align-items: center;
  text-align: center;
  padding-top: 1.5rem !important;

  background: #c4eeeb 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
}

.card-color-6 {
  background-color: #ffebe2;
}
.card-color-frutas {
  background: #f9ffd3 0% 0% no-repeat padding-box;
}

.center-item-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-info-calorias {
  font-family: 'Omnesb' !important;
  font-size: 0.8rem;
  padding: 5px !important;
  width: 100%;
  height: 4rem auto;
  margin-top: 1rem;
  align-items: center;
  text-align: center;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;

  background: #fdd3c0 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
}

.description-info-frutas {
  font-family: 'Omnesb' !important;
  font-size: 0.8rem;
  padding: 5px !important;
  width: 100%;
  height: 4rem auto;
  margin-top: 1rem;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;

  background: #eef7b8 0% 0% no-repeat padding-box;
  border-radius: 13px;
}

.card-color-verduras {
  background-color: #d8e2dc;
}

.description-info-verduras {
  font-family: 'Omnesb' !important;
  font-size: 0.8rem;
  padding: 5px !important;
  width: 100%;
  height: 4rem;
  margin-top: 1rem;
  padding-top: 1.5rem !important;

  background: #bdd6c6 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
}

.porpose-container-circle {
  background-color: red 0% 0% no-repeat padding-box;
  height: 113px;
}

.card-value-subtitle {
  margin-top: 1rem;
  font-family: 'Omnes_semibold' sans-serif;
}

.hidden-in-pdf {
  display: none;
}

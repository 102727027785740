.OrdersHistory {
  .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      margin-right: 20px;
    }
  }
  .history-wrapper {
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
}

.Cover {
    padding: 30px 20px;
    color: white;
    max-width: 1000px;
    margin: -20px auto 20px;
    position: relative;
    &::before {
        content: "";
        background-color: #323E48;
        height: 100%;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
    .MuiTypography-subtitle1, .MuiTypography-subtitle2 {
        font-size: 20px;
    }
    .MuiTypography-subtitle1 {
        color: #FFA489;
        margin: 20px 0;
    }
    img {
        margin: auto;
        display: flex;
    }
}
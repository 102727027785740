.SingleOptionSelector {
    width: 100%;
    figure {
        margin: 20px auto;
        display: table;
    }
    .MuiButton-root.MuiButton-contained {
        width: 100%;
        text-transform: none;
        margin-top: 20px;
        line-height: 18px;
        min-height: 36.5px;
        border-radius: 20px;
        max-width: 400px;
    }
}
.ProductBenefit {
    position: relative;
    padding: 80px 0;
    margin-bottom: 20px;
    .background {
      position: absolute;
      height: 100%;
      width: 200vw;
      left: 230px;
      border-radius: 60px;
      top: 0;
      z-index: -1;
      .image-1 {
        position: absolute;
        top: 0px;
        left: 350px;
        transform: rotate(-90deg);
      }
      .image-2 {
        position: absolute;
        width: 350px;
        left: 0;
        bottom: 0;
      }
    }
    .custom-packing {
      margin: auto;
      display: table;
      width: 100%;
      img {
        width: 100%;
      }
    }
    h3 {
      &,
      & + .MuiGrid-item {
        margin-bottom: 20px;
      }
    }
    .MuiGrid-container {
      // flex-direction: column;
      // max-height: 400px;
    }
    @media (max-width: 900px) {
      & {
        padding: 20px;
        .MuiGrid-container {
          flex-direction: column;
        }
        .background {
          border-radius: 20px;
          width: 100%;
          left: 0;
        }
        .custom-packing {
          position: relative;
          margin: 0 0 20px;
          top: 0;
          left: 0;
        }
      }
    }
  }

.FrequentOrder {
  .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      margin-right: 20px;
    }
  }
  .history-wrapper {
    .MuiGrid-item {
      text-align: center;
    }
  }
}

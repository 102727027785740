.card-color-corporal {
  background: #f4cffc 0% 0% no-repeat padding-box;
}

.card-value-text-recommendations {
  text-align: left;
  font: normal normal 0.9rem/0.9rem 'Omnesb';
  /* font: normal normal bold 4rem/3rem "result_bold"; */
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-family: 'Omnesb', sans-serif;
  /* font-weight: 5rem; */
}

.card-container-recommendations {
  width: 18rem;
  /* height: 12rem; */
  /* background: #2721cf31 0% 0% no-repeat padding-box; */
  border-radius: 40px;
  opacity: 1;
  padding: 1rem;
  margin-top: 1rem;
  margin: 0.6rem;
  font-weight: bold;
  font-family: 'Omnesb', sans-serif;
  font-weight: bolder;
}

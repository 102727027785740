.Home {
    padding: 0 20px;
    .ProcedureSteps {
        & + .MuiButton-root {
            margin: auto;
            display: block;
        }
    }
    .MuiTypography-h4 {
        text-align: center;
    }
    .ViewSection {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.Layout.home {
    .view-wrapper {
        padding-top: 0px;
        &::before {
            top: 500px;
        }
        .circle.green-top {
            top: 500px;
            display: initial;
        }
    }
    @media (max-width: 1048px) {
        .view-wrapper {
            .circle.green-top {
                top: 507px;
            }
        }
    }
    @media (max-width: 725px) {
        .view-wrapper {
            .circle.green-top {
                top: 530px;
            }
        }
    }
    @media (max-width: 651px) {
        .view-wrapper {
            .circle.green-top {
                top: 586px;
            }
        }
    }
    @media (max-width: 649px) {
        .view-wrapper {
            .circle.green-top {
                display: none;
            }
        }
    }
}
.Orders {
  min-height: 500px;
  margin: 20px 0px;
  .principal-line {
    margin-bottom: 40px;
  }
  .title-product-tracking {
    display: block;
  }
  .refund-btn {
    text-decoration: none;
    margin: 20px auto;
    display: table;
  }
}

.InputQuestion {
    align-items: center;
    justify-content: center;
    text-align: center;
    .icon{
        margin-top: 20px;
    }
    .MuiTypography-root {
        margin: 10px 0;
    }
    .MuiFormControl-root {
        margin-top: 20px;
        & + .MuiButton-root {
            margin-top: 20px;
        }
    }
}
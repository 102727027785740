.ProcedureSteps {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .step {
        display: flex;
        flex-direction: column;
        padding: 20px 10px;
        flex: calc(1 / 3);
        max-width: 347px;
        min-width: 347px;
        text-align: center;
    }
    figure {
        position: relative;
        img {
            width: 100%;
            height: auto;
        }
        .StepNumber {
            position: absolute;
            bottom: 10px;
            left: 10px;
        }
    }
    @media (max-width: 400px) {
        .step {
            max-width: 100%;
            flex: 1;
            min-width: 100%;
        }
        figure {
            .StepNumber {
                position: absolute;
                bottom: -17px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

.RenewPlan {
  max-width: 700px;
  margin: 0 auto 20px;
  .MuiCard-root {
    background: #07231F;
    color: white;
    .MuiGrid-container {
      justify-content: space-between;
      align-items: center;
    }
  }
  .Mui-disabled {
    color: rgb(255 255 255 / 50%) !important;
    background-color: #52c7db33 !important;
  }
  a {
    text-decoration: none;
  }
}
